<footer class="{{footerClass}}">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="logo"></a>
                    <p>Yapay Zeka Algoritmaları ile Market, Pazar, Ürün ve Müşteri analizlerini yapar ve TREND YÖN TAHMİNİ ile grafikleri hazırlar ve işinizi BÜYÜTÜR!</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            
            <div class="col-lg-2 col-md-3 col-sm-6">
                <div class="single-footer-widget">
                    <!--<h3>Resources</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/team">Our Scientists</a></li>
                        <li><a routerLink="/services">Our Services</a></li>
                        <li><a routerLink="/">Testimonials</a></li>
                        <li><a routerLink="/services-details">SaaS Solutions</a></li>
                        <li><a routerLink="/services-details">eCommerce</a></li>
                    </ul>-->
                </div>
            </div>

            <div class="col-lg-2 col-md-9 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Bağlantılar</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="">Ana Sayfa</a></li>
                        <li><a routerLink="/about-us">Hakkımızda</a></li>
                        <li><a routerLink="/services">Hizmetlerimiz</a></li>
                        <li><a routerLink="/services-details">Hizmet Detay</a></li>
                        <li><a routerLink="/contact">İletişim</a></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>İletişim</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Esenkent, Dudullu OSB, DES, 2. Cd. No:8/41 34776 Ümraniye/İstanbul.</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+905334513512">+90 (533) 451 3512</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:contact@trendictor.com">contact@trendictor.com</a></li>
                        <!--<li><i class='bx bxs-inbox'></i><a href="tel:+905334513512">+90 (533) 451 3512</a></li>-->
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>Copyright <i class='bx bx-copyright'></i>2021 <strong>Trendictor</strong>. Bütün hakları saklıdır.</p>
                </div>
                <!--
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
                -->
            </div>
        </div>
    </div>

    <div class="footer-map"><img src="assets/img/footer-map.png" alt="image"></div>
</footer>

<div class="go-top"><i class="flaticon-up"></i></div>